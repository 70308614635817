<template>
    <div class="qrcode" id="wx_qrcode">
    </div>
</template>
<script>
    export default {
        name:'index',
        data() {
            return {}
        },
        created() {

        },
        methods: {
        },
        mounted() {
            window.WwLogin({
                id: 'wx_qrcode', // 登录页面显示二维码的容器id
                appid: 'wxa44445e62d5493b8', // 企业微信的CorpID，在企业微信管理端查看
                agentid: '1000118',//this.$route.query.activityid == 'undefined' || this.$route.query.activityid == '10253584' ? '1000118' : '1000126', // 授权方的网页应用id，在具体的网页应用中查看
                redirect_uri: encodeURIComponent('https://thlive.thape.com.cn/login/code'), // 重定向的地址，需要进行encode
            })
        },
    }
</script>

